<template>
  <div class="pag-contato">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Contato</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
          <li>Home</li>
          <li><span>Contato</span></li>
        </ul>
      </div>
    </div>

    <div class="contato-form">
      <div class="contato-form-content">
        <div class="row">
          <div class="col col-1-2">
            <div class="formulario">
              <form
                :class="this.errors ? 'errors' : false"
                @submit.prevent="saveContato()"
              >
                <div class="row">
                  <div class="col col-1-1">
                    <label class="required">Nome</label>
                    <input
                      type="text"
                      v-model="nome"
                      required
                      @invalid="invalidateForm"
                    />
                    <span v-show="validations.nome" class="error-message"
                      >Este campo é obrigatório</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col col-1-2">
                    <label class="required">E-mail</label>
                    <input
                      type="text"
                      v-model="email"
                      required
                      @invalid="invalidateForm"
                      pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$"
                    />
                    <span v-show="validations.email" class="error-message"
                      >Este campo é obrigatório</span
                    >
                  </div>
                  <div class="col col-1-2">
                    <label class="required">Telefone</label>
                    <input
                      type="text"
                      v-model="telefone"
                      required
                      @invalid="invalidateForm"
                      maxlength="15"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                    <span v-show="validations.telefone" class="error-message"
                      >Este campo é obrigatório.</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col col-1-2">
                    <label>Empresa</label>
                    <input type="text" v-model="empresa" />
                  </div>
                  <div class="col col-1-2">
                    <label>Cargo</label>
                    <input type="text" v-model="cargo" />
                  </div>
                </div>
                <div class="row">
                  <div class="col col-1-2">
                    <label>Estado</label>
                    <select v-model="estado">
                      <option>Acre</option>
                      <option>Alagoas</option>
                      <option>Amapá</option>
                      <option>Amazonas</option>
                      <option>Bahia</option>
                      <option>Ceará</option>
                      <option>Distrito Federal</option>
                      <option>Espírito Santo</option>
                      <option>Goiás</option>
                      <option>Maranhão</option>
                      <option>Mato Grosso</option>
                      <option>Mato Grosso do Sul</option>
                      <option>Minas Gerais</option>
                      <option>Paraíba</option>
                      <option>Pará</option>
                      <option>Paraná</option>
                      <option>Pernambico</option>
                      <option>Piauí</option>
                      <option>Rio de Janeiro</option>
                      <option>Rio Grande do Norte</option>
                      <option>Rio Grande do Sul</option>
                      <option>Rondônia</option>
                      <option>Roraima</option>
                      <option>Santa Catarina</option>
                      <option>São Paulo</option>
                      <option>Sergipe</option>
                      <option>Tocantins</option>
                    </select>
                  </div>
                  <div class="col col-1-2">
                    <label>Cidade</label>
                    <input type="text" v-model="cidade" />
                  </div>
                </div>
                <div class="row">
                  <div class="col col-1-1">
                    <label class="required">Assunto</label>
                    <input
                      type="text"
                      v-model="assunto"
                      required
                      @invalid="invalidateForm"
                    />
                    <span v-show="validations.assunto" class="error-message"
                      >Este campo é obrigatório.</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col col-1-1">
                    <label class="required">Mensagem</label>
                    <textarea
                      v-model="mensagem"
                      required
                      @invalid="invalidateForm"
                    ></textarea>
                    <span v-show="validations.mensagem" class="error-message"
                      >Este campo é obrigatório.</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col col-1-1 btn-container-form">
                    <button class="btn btn-lg btn-sec" type="submit">
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col col-1-2">
            <div class="informacoes">
              <h5>Informações para contato</h5>
              <p>(31) 3399-2500</p>
              <h5>Onde Estamos</h5>
              <p>Avenida João Cesar de Oliveira, 5757</p>
              <p>Edifício Contemporâneo, Sala 907</p>
              <p>Beatriz, Contagem/MG</p>
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    id="gmap_canvas"
                    width="100%"
                    height="340px"
                    src="https://maps.google.com/maps?q=spdata&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                </div>
              </div>
              <h5>Horário de Atendimento</h5>
              <p>Seg - Sex: 7:30 às 12:00 e 13:30 às 17:00</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="canal-denuncias">
      <div class="container pad-lg">
        <h3 class="center" data-aos="fade-up">Canal de Denúncia</h3>
        <p class="left pad-top-md" data-aos="fade-up">
          <span class="text-sm">
            É um canal exclusivo da SPDATA para comunicação segura e, se
            desejada, anônima, de condutas consideradas antiéticas ou que violem
            os princípios éticos e padrões de conduta e/ou a legislação vigente.
          </span>
        </p>
        <p class="left pad-top-sm" data-aos="fade-up">
          <span class="text-sm">
            As informações aqui registradas serão recebidas por uma empresa
            independente e especializada, a PROTEGON, assegurando sigilo
            absoluto e o tratamento adequado de cada situação pela alta
            administração da SPDATA, sem conflitos de interesses.
          </span>
        </p>
        <div class="btn-container center">
          <a
            href="http://app.protegon.com.br/#/external_incident_complaint/3bd45c78"
            target="_blank"
            class="btn btn-lg btn-sec"
            data-aos="fade-up"
            >Acesse Aqui</a
          >
        </div>
      </div>
    </section>

    <Cta />

    <WhatsApp />
  </div>
</template>

<script>
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
import SetupApplicationService from "../services/SetupApplicationService.js";
export default {
  data() {
    return {
      nome: "",
      email: "",
      telefone: "",
      empresa: "",
      cargo: "",
      estado: "",
      cidade: "",
      assunto: "",
      mensagem: "",
      validations: {
        nome: false,
        email: false,
        telefone: false,
        mensagem: false,
      },
      setupApplicationService: new SetupApplicationService(),
      errors: false,
    };
  },
  name: "Home",
  components: {
    Cta,
    WhatsApp,
  },
  methods: {
    invalidateForm() {
      this.errors = true;
    },

    displaySuccessTooltip(message, type) {
      this.$notify({
        group: "main",
        text: message,
        type: type,
      });
    },

    async saveContato() {
      const payload = {
        nome: this.nome,
        email: this.email,
        telefone: this.telefone,
        empresa: this.cargo,
        cargo: this.cargo,
        estado: this.estado,
        cidade: this.cidade,
        assunto: this.assunto,
        mensagem: this.mensagem,
      };

      if (this.checkForm()) {
        try {
          await this.setupApplicationService.setContato(payload);
          this.displaySuccessTooltip(
            "Recebemos seu pedido e entraremos em contato assim que possível.",
            "success"
          );
        } catch {
          this.displaySuccessTooltip(
            "Não conseguimos salvar seu pedido de contato no momento.",
            "error"
          );
        }
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      if (
        this.nome &&
        this.validEmail(this.email) &&
        this.telefone &&
        this.assunto &&
        this.mensagem
      ) {
        return true;
      }

      this.validations = {};

      this.validations.nome = !this.nome;
      this.validations.telefone = !this.telefone;
      this.validations.assunto = !this.assunto;
      this.validations.mensagem = !this.mensagem;
      this.validations.email = !this.validEmail(this.email);
    },
    Home() {
      window.location.href = "/Home";
    },
  },
};
</script>
