<template>
  <div class="pag-politica-privacidade">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Política de Privacidade</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li><span>Política de Privacidade</span></li>
        </ul>
      </div>
    </div>

    <section id="atencao-a-politica">
      <div class="container pad-top-md">
        <p>
          Sua atenção à nossa política é muito importante para a consolidação de
          nossa relação comercial e de trabalho, uma vez que RESPEITO AO
          INDIVÍDUO é um valor inestimável para nossa organização, e a
          transparência com o uso de seus dados é uma das formas que reiteramos
          esse nosso compromisso.
        </p>
      </div>
    </section>

    <section id="fonte-coleta-dados">
      <div class="container pad-md" data-aos="fade-up">
        <h3 class="left">
          Quais são as fontes de coleta dos seus dados pessoais?
        </h3>

        <h5 class="pad-top-md">Sites da SPDATA</h5>
        <p>
          Todos os nossos sites podem ser utilizados para coleta de dados
          pessoais. Isso inclui tanto o site que operamos diretamente através
          dos nossos domínios e endereços IPs, quanto sites ou páginas que Nós
          estabelecemos em serviços de terceiros, como Facebook, Linkedin, e
          demais terceiros que ofertam esse tipo de serviço.
        </p>

        <h5 class="pad-top-sm">
          Correio Eletrônico e sistemas de troca de mensagens instantâneas:
        </h5>
        <p>
          Serviços utilizados para manter comunicações eletrônicas entre Você e
          a SPDATA, incluindo aqueles disponibilizados diretamente por Nós, ou
          serviços de terceiros como WhatsApp, Telegram, SMS (serviço de
          mensagens curtas) e similares.
        </p>

        <h5 class="pad-top-sm">Anúncios, propagandas e formulários online:</h5>
        <p>
          Interações com qualquer tipo de anúncios, propagandas e formulários
          online da SPDATA.
        </p>

        <h5 class="pad-top-sm">Registros offline:</h5>
        <p>
          Registros preenchidos offline, distribuídos durante eventos e outras
          interações com a SPDATA.
        </p>

        <h5 class="pad-top-sm">Dados recebidos de terceiros:</h5>
        <p>
          Incluindo, mas não limitado a, redes sociais e sites de terceiros
          como, por exemplo, Facebook, Instagram, LinkedIin, e similares,
          serviços de agregadores de dados, parceiros da SPDATA, fontes públicas
          e dados recebidos durante aquisição de outras empresas.
        </p>

        <h5 class="pad-top-sm">SMA – Suporte e Manutenção de Aplicação:</h5>
        <p>
          Comunicações realizadas com Você através das nossas centrais de
          atendimento.
        </p>
      </div>
    </section>

    <section id="como-coletamos-seus-dados">
      <div class="container pad-md" data-aos="fade-up">
        <h3 class="left">
          Como coletamos seus dados e quais são?
        </h3>
        <ul>
          <li>
            Programas de Incentivo com foco no uso de EPIs e cumprimento dos
            procedimentos de segurança.
          </li>
          <li>Treinamentos Técnicos e Comportamentais.</li>
          <li>Campanhas de Comunicação Interna.</li>
          <li>Programa de Saúde e Bem-estar – parceria com a Unimed.</li>
          <li>Universidade SPDATA – incentivo à educação continuada.</li>
          <li>Programas de Desenvolvimento das Lideranças, entre outros.</li>
        </ul>
      </div>
    </section>

    <section id="sobre-dados-criancas">
      <div class="container pad-md" data-aos="fade-up">
        <h3 class="left">
          Sobre Dados Pessoais de Crianças e Adolescentes
        </h3>
        <p class="left pad-top-md">
          <span class="text-sm">
            A SPDATA não solicita, coleta, processa, armazena ou compartilha,
            conscientemente, dados pessoais de crianças e adolescentes menores
            de idade. Se descobrirmos a ocorrência de qualquer tipo de manuseio
            dos referidos dados, forma não-intencional, removeremos os dados
            pessoais daquela criança ou adolescente de nossos registros
            rapidamente.
          </span>
        </p>

        <p class="left pad-top-sm">
          <span class="text-sm">
            Entretanto, a SPDATA pode coletar dados pessoais de crianças e
            adolescentes diretamente dos seus pais ou responsáveis legais, e com
            consentimento explícito, e de acordo com as regras da legislação
            vigente</span
          >
        </p>
      </div>
    </section>

    <section id="como-sao-utilizados">
      <div class="container pad-md" data-aos="fade-up">
        <h3 class="left">
          O que são cookies e como utilizamos os seus?
        </h3>

        <h5 class="pad-top-md">Cookies de sessão:</h5>
        <p class="left">
          <span class="text-sm">
            São Cookies de uso temporário, que são excluídos no momento em que
            Você fecha o seu navegador. Quando Você reinicia o seu navegador e
            volta para o site que criou o cookie, esse site trata Você como um
            novo visitante.
          </span>
        </p>

        <h5 class="pad-top-sm">Cookies persistentes:</h5>
        <p class="left">
          <span class="text-sm">
            São aqueles Cookies que permanecem no seu navegador até Você
            deletá-los manualmente ou até o seu navegador deletá-los de acordo
            com o período de duração estabelecido pelo cookie. Esses Cookies
            reconhecerão seu retorno como visitante a um site ou serviço da
            SPDATA.
          </span>
        </p>

        <h5 class="pad-top-sm">Cookies necessários:</h5>
        <p class="left">
          <span class="text-sm">
            São Cookies estritamente necessários para a operação de um site ou
            serviço compatível da SPDATA. Eles permitem que Você navegue pelo
            site e use nossos recursos.
          </span>
        </p>

        <h5 class="pad-top-sm">
          Cookies que nos mandam informações sobre você:
        </h5>
        <p class="left">
          <span class="text-sm">
            Nós colocamos esse tipo de Cookies em um site ou serviço compatível
            com a SPDATA e este tipo de Cookie só podem ser lidos por nossos
            sites e serviços compatíveis.
          </span>
        </p>

        <h5 class="pad-top-sm">Cookies em propagandas da SPDATA:</h5>
        <p class="left">
          <span class="text-sm">
            Colocamos Cookies em propagandas e anúncios que são exibidos em
            sites e serviços compatíveis de terceiros. Obtemos informações
            através desses Cookies quando Você clica ou interage com a
            propaganda ou anúncio. Neste caso, a SPDATA está colocando um cookie
            “de terceiro”. Nós podemos usar esses dados obtidos por Cookies de
            terceiros para mandar-lhe outras propagandas que acreditamos ser
            relevantes ou de seu interesse com base no seu comportamento
            anterior.
          </span>
        </p>

        <h5 class="pad-top-sm">
          Cookies que compartilham suas informações para terceiros:
        </h5>
        <p class="left">
          <span class="text-sm">
            São Cookies colocados em um site da SPDATA por nossas companhias
            parceiras, como serviços de anúncios online. Eles podem usar os
            dados coletados por esses Cookies para lhe enviar anonimamente
            propagandas direcionadas de outros sites, com base em sua visita a
            sites ou serviços compatíveis da SPDATA.
          </span>
        </p>

        <p class="left pad-top-md">
          <span class="text-sm">
            Os sites e serviços compatíveis da SPDATA também podem usar outras
            tecnologias de rastreamento similares a Cookies, que podem coletar
            informações tais como endereços IP, arquivos de registro e
            sinalizadores da web, dentre outras. Esses dados também são
            utilizados para nos ajudar a adaptar os sites e demais serviços
            compatíveis da SPDATA às suas necessidades pessoais.
          </span>
        </p>
        <p class="left pad-top-sm">
          <span class="text-sm">
            É importante lembrar que cabe a Você assegurar que as configurações
            do seu computador ou dispositivo portátil reflitam se Você consente
            em aceitar Cookies ou não.
          </span>
        </p>
      </div>
    </section>

    <section id="finalidade-dados">
      <div class="container pad-md" data-aos="fade-up">
        <h3 class="left">
          Com quais finalidades meus dados são utilizados?
        </h3>
        <p class="pad-top-md">
          <span class="text-sm">
            Os itens a seguir descrevem as finalidades para as quais a SPDATA
            coleta seus Dados Pessoais, e os diferentes tipos de Dados Pessoais
            que coletamos para cada finalidade. Note, por favor, que nem todos
            os usos abaixo serão relevantes para todos os indivíduos e podem se
            aplicar apenas a situações específicas.
          </span>
        </p>
        <ul>
          <li>Serviços ao Cliente</li>
          <li>Realização de concursos, promoções e ações de marketing</li>
          <li>Redes sociais e sites de terceiros</li>
          <li>Personalização (offline e online)</li>
          <li>Motivos legais ou fusão/aquisição</li>
          <li>
            Outras finalidades e situações em geral: De acordo com a legislação
            vigente, a SPDATA utiliza seus Dados Pessoais para outras
            finalidades gerais de negócio, como fazer manutenção em sua conta,
            conduzir pesquisas internas ou de mercado e medir a efetividade de
            nossas campanhas publicitárias. Nós nos reservamos o direito, se
            Você tiver contas SPDATA, de integrar estas contas em uma conta
            única. Nós também usamos seus Dados Pessoais para gerenciamento e
            operação de nossas comunicações, TI e sistemas de segurança e
            proteção de dados.
          </li>
        </ul>
      </div>
    </section>

    <section id="sobre-alteracoes">
      <div class="container pad-md" data-aos="fade-up">
        <h3 class="left">
          Sobre as alterações desta Política de Privacidade
        </h3>
        <p class="pad-top-md">
          <span class="text-sm">
            Sempre que a SPDATA decidir mudar a forma que tratamos seus Dados
            Pessoais, esta Política será atualizada. Nos reservamos o direito de
            fazer alterações às nossas práticas e a esta Política a qualquer
            tempo, desde que mantida a conformidade com a legislação vigente.
          </span>
        </p>
        <p class="pad-top-sm">
          <span class="text-sm">
            Recomendamos que Você a acesse frequentemente, ou sempre que tiver
            dúvidas, para ver quaisquer atualizações ou mudanças à nossa
            Política de Privacidade.
          </span>
        </p>
      </div>
    </section>

    <section id="encarregado">
      <div class="container pad-md" data-aos="fade-up">
        <h3 class="left">
          Encarregado de Proteção de Dados SPDATA
        </h3>
        <p class="pad-top-md">
          <span class="text-sm"> Contato: João Gonçalves </span>
        </p>
        <p>
          <span class="text-sm"> E-mail: privacidade@spdata.com.br </span>
        </p>
        <p>
          <span class="text-sm"> Telefone: 31-3399-2500 </span>
        </p>
      </div>
    </section>

    <Cta />
  </div>
</template>

<script>
import Cta from "@/components/Cta.vue";
export default {
  name: "politica-privacidade",
  components: {
    Cta,
  },

  methods: {
    Home() {
      window.location.href = "/";
    },
  },
};
</script>