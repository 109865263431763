<template>
  <div class="pag-solucoes pag-solucoes-clinicas">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <section id="breadcrumb">
      <div class="container">
        <ul>
          <li>Home</li>
          <li>Soluções</li>
          <li><span>SPDATA Minha Clínica</span></li>
        </ul>
      </div>
    </section>

    <MenuSolucoes />
    <!--
    <div class="abas">
      <div class="abas-content">
        <div class="row">
          <div class="col col-1-4">
            <span>SPDATA - Minha Clínica</span>
          </div>
        </div>
      </div>
    </div>
-->

    <!--<div class="modulos">
      <div class="modulos-content">
        <div class="row">
          <div class="col col-1-1">
            <div class="row">
              <div
                class="col col-1-6"
                data-aos="fade-up"
                data-aos-duration="200"
              >
                <a
                  href="#"
                  class="modulo-card"
                  data-toggle="modal"
                  data-target="#teleConsulta"
                >
                  <span class="container-img clinicas-tele-consulta"></span>
                  <span>Teleconsulta</span>
                </a>
              </div>
              <div
                class="col col-1-6"
                data-aos="fade-up"
                data-aos-duration="400"
              >
                <a
                  href="#"
                  class="modulo-card"
                  data-toggle="modal"
                  data-target="#agendaVirtual"
                >
                  <span class="container-img clinicas-agenda-virtual"></span>
                  <span>Agenda Virtual Inteligente</span>
                </a>
              </div>
              <div
                class="col col-1-6"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <a
                  href="#"
                  class="modulo-card"
                  data-toggle="modal"
                  data-target="#recepcaoCadastro"
                >
                  <span class="container-img clinicas-recepcao-cadastro"></span>
                  <span>Recepção e Cadastro</span>
                </a>
              </div>
              <div
                class="col col-1-6"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a
                  href="#"
                  class="modulo-card"
                  data-toggle="modal"
                  data-target="#lojaVirtual"
                >
                  <span class="container-img clinicas-loja-virtual"></span>
                  <span>Venda de Produtos</span>
                </a>
              </div>
              <div
                class="col col-1-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <a
                  href="#"
                  class="modulo-card"
                  data-toggle="modal"
                  data-target="#prontuarioEletronico"
                >
                  <span
                    class="container-img clinicas-prontuario-eletronico"
                  ></span>
                  <span>Prontuário Eletrônico</span>
                </a>
              </div>

              <div
                class="col col-1-6"
                data-aos="fade-up"
                data-aos-duration="200"
              >
                <a
                  href="#"
                  class="modulo-card"
                  data-toggle="modal"
                  data-target="#gerenciamentoGuias"
                >
                  <span
                    class="container-img clinicas-gerenciamento-guias"
                  ></span>
                  <span>Gerenciamento de Guias - Repasse</span>
                </a>
              </div>

              <div
                class="col col-1-6"
                data-aos="fade-up"
                data-aos-duration="400"
              >
                <a
                  href="#"
                  class="modulo-card"
                  data-toggle="modal"
                  data-target="#relatoriosGerenciais"
                >
                  <span
                    class="container-img clinicas-relatorios-gerenciais"
                  ></span>
                  <span>Relatórios Gerenciais - Dashboard</span>
                </a>
              </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#arquivamentoImagens"
            >
              <span class="container-img clinicas-arquivamento-imagens"></span>
              <span>Arquivamento de Imagens</span>
            </a>
          </div>

            </div>
            <div class="row">
              <div
                class="col col-1-6"
                data-aos="fade-up"
                data-aos-duration="200"
              >
                <a
                  href="#"
                  class="modulo-card"
                  data-toggle="modal"
                  data-target="#controleFinanceiro"
                >
                  <span
                    class="container-img clinicas-controle-financeiro"
                  ></span>
                  <span>Controle Financeiro</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col col-1-3"></div>
        </div>
      </div>
    </div>-->

    <section id="minha-clinica-planos">
      <div class="container">
        <div class="row">
          <div class="col col-1-4">
            <h1 data-aos="fade-up" data-aos-duration="300">
              Conheça <b>nossos planos</b> e escolha o mais adequado para o seu
              <b> negócio.</b>
            </h1>
          </div>
          <div class="col col-3-4">
            <div class="row">
              <div
                class="col col-1-3"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <div class="card-plano">
                  <header>
                    <p class="header-plano">PLANO</p>
                    <p class="tipo-plano">Padrão Mensal</p>
                  </header>
                  <div class="desconto-30">
                    <img src="../../../assets/images/desconto-30.png" alt="desconto 30">
                  </div>
                  <p class="valor-de">199,90</p>
                  <p class="valor-plano"><span>R$</span> 139,<span>90*</span></p>
                  <p class="mensal">por mês</p>
                  <p class="desc-plano">

                  </p>
                  <p class="teste-gratis">
                    <a
                      href="https://cadastro.spdataminhaclinica.com.br/trial?subscrition_plan=eyJpZCI6IlBMQU5PX1BBRFJBT19NRU5TQUxfMiIsImFtb3VudCI6IlIkwqAxOTksOTAiLCJhbW91bnRWYWx1ZSI6MTk5LjksInR5cGVUcmFuc2FjdGlvbiI6IlJFQ09SUkVOVEUiLCJuYW1lIjoiUGxhbm8gUGFkcsOjbyBNZW5zYWwiLCJkZXNjcmlwdGlvbiI6IlBsYW5vIFBhZHLDo28gTWVuc2FsIiwidHJpYWxfZGF5c19lZmZlY3RpdmUiOjE1LCJ0cmlhbF9kYXlzX2Rpc3BsYXkiOjE1LCJjdXBvbV9kZWZhdWx0Ijp7Im5vbWUiOiJEZXNjb250byBQYWRyw6NvIE1lbnNhbCIsInZhbG9yRGVzY29udG8iOjAuMzAwMTV9fQ==&redirectTo=https://web.spdataminhaclinica.com.br"
                      target="_blank"
                      class="btn btn-lg btn-pri"
                      >Teste grátis por 15 dias</a
                    >
                  </p>
                  <p class="fidelidade"></p>
                  <p class="assinatura"><strong> Assinatura para 01 usuário administrativo + 01 usuário profissional com 01 certificado digital A3 ICP Brasil SafeID armazenado na nuvem com Carimbo do Tempo. </strong></p>
                  <p>
                    <!-- <a
                      class="btn btn-lg btn-pri"
                      href="javascript:openSignup('intermediario');"
                      >Assine Agora</a
                    > -->
                    <a
                      class="btn btn-lg btn-pri"
                      target="_blank"
                      href="https://cadastro.spdataminhaclinica.com.br/?subscrition_plan=eyJpZCI6IlBMQU5PX1BBRFJBT19NRU5TQUxfMiIsImFtb3VudCI6IlIkwqAxOTksOTAiLCJhbW91bnRWYWx1ZSI6MTk5LjksInR5cGVUcmFuc2FjdGlvbiI6IlJFQ09SUkVOVEUiLCJuYW1lIjoiUGxhbm8gUGFkcsOjbyBNZW5zYWwiLCJkZXNjcmlwdGlvbiI6IlBsYW5vIFBhZHLDo28gTWVuc2FsIiwidHJpYWxfZGF5c19lZmZlY3RpdmUiOjE1LCJ0cmlhbF9kYXlzX2Rpc3BsYXkiOjE1LCJjdXBvbV9kZWZhdWx0Ijp7Im5vbWUiOiJEZXNjb250byBQYWRyw6NvIE1lbnNhbCIsInZhbG9yRGVzY29udG8iOjAuMzAwMTV9fQ==&redirectTo=https://web.spdataminhaclinica.com.br"
                      >Assine Agora</a
                    >
                  </p>
                </div>
              </div>
              <div
                class="col col-1-3 center"
                data-aos="fade-up"
                data-aos-duration="900"
              >
                <div class="card-plano">
                  <header>
                    <p class="header-plano">PLANO</p>
                    <p class="tipo-plano">Padrão Anual</p>
                  </header>
                  <div class="desconto-35">
                    <img src="../../../assets/images/desconto-35.png" alt="desconto 35">
                  </div>
                  <p class="valor-de">R$ 199,90</p>
                  <p class="valor-plano"><span>R$</span> 129,<span>90*</span></p>
                  <p class="mensal">por mês</p>
                  <p class="desc-plano">

                  </p>
                  <p class="teste-gratis">
                    <a
                      href= "https://cadastro.spdataminhaclinica.com.br/trial?subscrition_plan=eyJpZCI6IlBMQU5PX1BBRFJBT19BTlVBTF8zIiwiYW1vdW50IjoiUiTCoDE5OSw5MCIsImFtb3VudFZhbHVlIjoxOTkuOSwidHlwZVRyYW5zYWN0aW9uIjoiUkVDT1JSRU5URV9BTlVBTCIsIm5hbWUiOiJQbGFubyBQYWRyw6NvIEFudWFsIiwiZGVzY3JpcHRpb24iOiJQbGFubyBQYWRyw6NvIEFudWFsIiwidHJpYWxfZGF5c19lZmZlY3RpdmUiOjE1LCJ0cmlhbF9kYXlzX2Rpc3BsYXkiOjE1LCJjdXBvbV9kZWZhdWx0Ijp7Im5vbWUiOiJEZXNjb250byBQYWRyw6NvIEFudWFsIiwidmFsb3JEZXNjb250byI6MC4zNTAyfX0=&redirectTo=https://web.spdataminhaclinica.com.br"
                      target="_blank"
                      class="btn btn-lg btn-pri"
                      >Teste grátis por 15 dias</a
                    >
                  </p>
                  <p class="fidelidade">*Fidelidade de 12 meses</p>
                  <p class="assinatura"><strong> Assinatura para 01 usuário administrativo + 01 usuário profissional com 01 certificado digital A3 ICP Brasil SafeID armazenado na nuvem com Carimbo do Tempo. </strong></p>
                  <p>
                    <a
                      href="https://cadastro.spdataminhaclinica.com.br/?subscrition_plan=eyJpZCI6IlBMQU5PX1BBRFJBT19BTlVBTF8zIiwiYW1vdW50IjoiUiTCoDE5OSw5MCIsImFtb3VudFZhbHVlIjoxOTkuOSwidHlwZVRyYW5zYWN0aW9uIjoiUkVDT1JSRU5URV9BTlVBTCIsIm5hbWUiOiJQbGFubyBQYWRyw6NvIEFudWFsIiwiZGVzY3JpcHRpb24iOiJQbGFubyBQYWRyw6NvIEFudWFsIiwidHJpYWxfZGF5c19lZmZlY3RpdmUiOjE1LCJ0cmlhbF9kYXlzX2Rpc3BsYXkiOjE1LCJjdXBvbV9kZWZhdWx0Ijp7Im5vbWUiOiJEZXNjb250byBQYWRyw6NvIEFudWFsIiwidmFsb3JEZXNjb250byI6MC4zNTAyfX0=&redirectTo=https://web.spdataminhaclinica.com.br"
                      target="_blank"
                      class="btn btn-lg btn-pri"
                      >Assine Agora</a
                    >
                  </p>
                </div>
              </div>

              <div
                class="col col-1-3"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div class="card-plano">
                  <header>
                  <p class="header-plano">PLANO</p>
                  <p class="tipo-plano-personalizado">Personalizado</p>
                  </header>
                  <p class="contato-especialista">
                    <span>Fale com um especialista...</span>
                  </p>
                  <p class="desc-plano-perso">

                  </p>
                    <div class="boneca"></div>
                    <a
                      class="btn btn-lg btn-pri"
                      data-target="#formPlanos"
                      data-toggle="modal"
                      >Clique Aqui</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col col-1-1">
                            <p class="obs">
                * Condições especiais para campanha de lançamento, podendo haver
                alteração para novas assinaturas, sem comunicação prévia.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- #### FORMULÁRIO PLANOS #### -->
    <div id="formPlanos" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close closebuttonx" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row group">
              <div class="col col-2-4">
                <h5 class="titulo">Solicitar Orçamento</h5>
              </div>
            </div>
            <form class="formulario" @submit.prevent="submit">
              <div class="row">
                <div class="col col-1-1">
                  <ul>
                    <li>
                      <input
                        type="text"
                        placeholder="Nome *"
                        required
                        v-model="nome"
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="E-mail *"
                        required
                        v-model="email"
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="Telefone (DDD + Número) * "
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        required
                        v-model="telefone"
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="Razão Social *"
                        required
                        v-model="razaosocial"
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="Cargo *"
                        required
                        v-model="cargo"
                      />
                    </li>
                    <li>
                      <select id="estado" name="estado" v-model="estado">
                        <option value="">Estado *</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </select>
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="Cidade *"
                        required
                        v-model="cidade"
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        placeholder="Quantidade de Usuários *"
                        required
                        v-model="qtdusuarios"
                      />
                    </li>
                    <li>
                      <textarea
                        placeholder="Mensagem *"
                        v-model="mensagem"
                      ></textarea>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col col-1-1">
                  <button class="btn btn-sm btn-pri" type="submit">
                    Enviar
                  </button>
                  <button class="btn btn-sm btn-sec" type="reset">
                    Limpar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- ##### MODAL ##### -->

    <div id="teleConsulta" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="modal-container-img modal-clinicas-tele-consulta"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Teleconsulta</h3>
                  <div class="modulo-descricao-texto descricao-clinicas">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Acesso do paciente pelo portal e app exclusivos;</li>
                      <li>Envio do link de consulta pelo WhatsApp;</li>
                      <li>Sala de espera do paciente;</li>
                      <li>Atenda o paciente do consultório ou de casa;</li>
                      <li>
                        Multiplataforma, seu paciente pode fazer a consulta do
                        computador ou celular.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="agendaVirtual" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="modal-container-img modal-clinicas-agenda-virtual"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Agenda Virtual Inteligente</h3>
                  <div class="modulo-descricao-texto descricao-clinicas">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Sua agenda disponível para uma comunidade que cresce a
                        cada dia;
                      </li>
                      <li>
                        Flexibilidade para disponibilizar horários para consulta
                        e teleconsulta;
                      </li>
                      <li>
                        Personalização e bloqueios dos horários por
                        profissional;
                      </li>
                      <li>Acesso do paciente pelo portal e app exclusivos;</li>
                      <li>Agendamento sem complicações;</li>
                      <li>
                        Assistente que filtra os horários disponíveis na agenda;
                      </li>
                      <li>Agenda de salas e equipamentos.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="recepcaoCadastro" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="modal-container-img modal-clinicas-recepcao-cadastro"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Recepção e Cadastro</h3>
                  <div class="modulo-descricao-texto descricao-clinicas">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Cadastro completo, rápido e eficaz de paciente,
                        profissionais e produtos;
                      </li>
                      <li>
                        Ficha de identificação do paciente e de produtos com
                        foto;
                      </li>
                      <li>Atendimento com apenas um clique;</li>
                      <li>Sala de espera dos pacientes.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div id="lojaVirtual" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="modal-container-img modal-clinicas-loja-virtual"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Venda de Produtos</h3>
                  <div class="modulo-descricao-texto descricao-clinicas">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Controle de pacientes em fase de testes;</li>
                      <li>
                        Produtos que estão em testes e o tempo previsto de
                        volta;
                      </li>
                      <li>
                        Alerta com a indicação de produtos em atraso na entrega;
                      </li>
                      <li>
                        Controle de Dispositivos enviados para manutenção;
                      </li>
                      <li>Nota Fiscal.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    -->

    <div id="prontuarioEletronico" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="
                      modal-container-img modal-clinicas-prontuario-eletronico
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Prontuário Eletrônico</h3>
                  <div class="modulo-descricao-texto descricao-clinicas">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Templates personalizados para registros clínicos;</li>
                      <li>Controle de audiometria e tonometria;</li>
                      <li>Integração com prescrição eletrônica Nexodata;</li>
                      <li>Atestado;</li>
                      <li>Solicitação de exames;</li>
                      <li>Importação de imagens;</li>
                      <li>Total privacidade dos seus registros clínicos;</li>
                      <li>
                        Anexos diretamente da câmera do celular ou galeria.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div id="arquivamentoImagens" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="modal-container-img modal-clinicas-arquivamento-imagens"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Arquivamento de Imagens</h3>
                  <div class="modulo-descricao-texto">
                     <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Templates personalizados para registros clínicos;</li>
                      <li>Controle de audiometria e tonometria;</li>
                      <li>Integração com prescrição eletrônica Nexodata;</li>
                      <li>Atestado;</li>
                      <li>Solicitação de exames</li>
                      <li>Importação de imagens</li>
                      <li>Total privacidade dos seus registros clínicos</li>
                      <li>Anexos diretamente da câmera do celular ou galeria</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->

    <div id="gerenciamentoGuias" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="
                      modal-container-img modal-clinicas-gerenciamento-guias
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Gerenciamento de Guias - Repasse</h3>
                  <div class="modulo-descricao-texto descricao-clinicas">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Gerenciamento de Guias de convênio;</li>
                      <li>Acompanhamento de repasse e faturamento;</li>
                      <li>Vendas efetuadas: por mês, ano e por produto.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="relatoriosGerenciais" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="
                      modal-container-img modal-clinicas-relatorios-gerenciais
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Relatórios Gerenciais - Dashboard</h3>
                  <div class="modulo-descricao-texto descricao-clinicas">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Agendas;</li>
                      <li>Atendimentos;</li>
                      <li>Tipo de atendimento, Profissional, entre outros;</li>
                      <li>Vendas efetuadas: por mês, ano e por produto;</li>
                      <li>Contas a pagar e receber por centro de custo.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="controleFinanceiro" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-clinicas center">
                  <span
                    class="
                      modal-container-img modal-operadoras-gestao-financeira
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Controle Financeiro</h3>
                  <div class="modulo-descricao-texto descricao-clinicas">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Conta a pagar;</li>
                      <li>Conta a receber;</li>
                      <li>Controle orçamentário por centro de custo.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import SetupApplicationService from "../../../services/SetupApplicationService";
import { env } from "../../../config/env.js";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
  },
  data: function () {
    return {
      nome: "",
      email: "",
      telefone: "",
      razaosocial: "",
      cargo: "",
      estado: "",
      cidade: "",
      qtdusuarios: "",
      mensagem: "",
      setupApplicationService: new SetupApplicationService(),
      url: env.signupUrl,
      urlRedirect: env.urlRedirect

    };
  },
  methods: {
    Home() {
      window.location.href = "/";
    },
    submit() {
      let apiModel = {
        nome: this.nome,
        email: this.email,
        telefone: this.telefone,
        razaoSocial: this.razaosocial,
        cargo: this.cargo,
        estado: this.estado,
        cidade: this.cidade,
        quantidadeUsuarios: this.qtdusuarios,
        mensagem: this.mensagem,
      };

      this.setupApplicationService.realizarOrcamentoMinhaClinica(apiModel);

      document.getElementsByClassName("closebuttonx")[0].click();
    },
  },
};
</script>
