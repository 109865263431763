var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pag-solucoes pag-solucoes-operadoras"},[_vm._m(0),_vm._m(1),_c('MenuSolucoes'),_c('div',{staticClass:"abas"},[_c('div',{staticClass:"abas-content"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/solucoes/operadoras-de-planos/controle-de-planos"}},[_vm._v("Controle de Planos")])],1),_vm._m(2),_c('li',[_c('router-link',{attrs:{"to":"/solucoes/operadoras-de-planos/sistema-de-contabilidade-gerencial"}},[_vm._v("Contabilidade Gerencial")])],1),_c('li',[_c('router-link',{attrs:{"to":"/solucoes/operadoras-de-planos/sistema-de-gerenciamento-patrimonial"}},[_vm._v("Gerenciamento Patrimonial")])],1)])])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('Cta'),_c('WhatsApp')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pag-title"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title center"},[_vm._v("Soluções")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"breadcrumb"}},[_c('div',{staticClass:"container"},[_c('ul',[_c('li',[_vm._v("Home")]),_c('li',[_vm._v("Soluções")]),_c('li',[_vm._v("SPDATA Gestão de Operadoras")]),_c('li',[_c('span',[_vm._v("Controle Financeiro")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("Controle Financeiro")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modulos"},[_c('div',{staticClass:"modulos-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-1-6",attrs:{"data-aos":"fade-up","data-aos-duration":"200"}},[_c('a',{staticClass:"modulo-card",attrs:{"href":"#","data-toggle":"modal","data-target":"#contasPagarReceber"}},[_c('span',{staticClass:"container-img operadoras-contas-pagar-receber"}),_c('span',[_vm._v("Contas à Pagar e a Receber")])])]),_c('div',{staticClass:"col col-1-6",attrs:{"data-aos":"fade-up","data-aos-duration":"400"}},[_c('a',{staticClass:"modulo-card",attrs:{"data-toggle":"modal","data-target":"#relatoriosGerenciais"}},[_c('span',{staticClass:"container-img operadoras-relatorios-gerenciais"}),_c('span',[_vm._v("Relatórios Gerenciais")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"contasPagarReceber","role":"dialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-1-3"},[_c('div',{staticClass:"segmento-operadoras center"},[_c('span',{staticClass:"modal-container-img modal-operadoras-contas-pagar-receber"})])]),_c('div',{staticClass:"col col-2-3"},[_c('div',{staticClass:"modulo-descricao"},[_c('h3',[_vm._v("Contas à Pagar e a Receber")]),_c('div',{staticClass:"modulo-descricao-texto descricao-operadoras"},[_c('p')])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"relatoriosGerenciais","role":"dialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-1-3"},[_c('div',{staticClass:"segmento-operadoras center"},[_c('span',{staticClass:"modal-container-img modal-operadoras-relatorios-gerenciais"})])]),_c('div',{staticClass:"col col-2-3"},[_c('div',{staticClass:"modulo-descricao"},[_c('h3',[_vm._v("Relatórios Gerenciais")]),_c('div',{staticClass:"modulo-descricao-texto descricao-operadoras"},[_c('p')])])])])])])])])
}]

export { render, staticRenderFns }