<template>
  <div class="pag-solucoes pag-solucoes-operadoras">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão de Operadoras</li>
          <li><span>Faturamento</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content-5">
        <ul>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/gestao-operadora/#n"
              >Gestão da Operadora</router-link
            >
          </li>
          <li>
            <span>Faturamento</span>
          </li>
           <li>
            <router-link to="/solucoes/operadoras-de-planos/controle-administrativo/#n"
              >Controle Administrativo</router-link
            >
          </li>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/cartao-saude/#n"
              >Cartão Saúde</router-link
            >
          </li>
           <li>
            <router-link
              to="/solucoes/operadoras-de-planos/ans-agencia-nacional-de-saude/#n"
              >ANS</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="modulos">
      <div class="modulos-content">
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#faturamento"
            >
              <span
                class="container-img operadoras-faturamento"
              ></span>
              <span>Faturamento</span>
            </a>
          </div>

        </div>
      </div>
    </div>

  <!-- ##### MODAL ##### -->

    <div id="faturamento" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-operadoras-faturamento"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Faturamento</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Faturamento eletrônico por meio de XML e <i>Web services</i> de lote guias no padrão TISS;</li>
                      <li>Parametrização de pagamento e tabelas aos prestadores individualizados, 
                      permitindo uma flexibilidade de contratos;</li>
                      <li>Gerenciamento das guias e seus lançamentos em tela única, facilitando a 
                      transição dos diversos tipos de lançamentos em guias mais complexas;</li>
                      <li>Gerenciamento dos protocolos de faturamento, oferecendo uma visão ampla 
                      das guias na competência selecionada;</li>
                      <li>Integração dos pagamentos nos módulos RPA e Financeiro.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   

    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
    methods: {
        Home() {
      window.location.href = '/Home'
    },
  }
};
</script>
