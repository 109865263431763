<template>
  <div class="pag-solucoes pag-solucoes-operadoras">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão de Operadoras</li>
          <li><span>Controle Financeiro</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div class="abas">
      <div class="abas-content">
        <ul>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/controle-de-planos"
              >Controle de Planos</router-link
            >
          </li>
          <li>
            <span>Controle Financeiro</span>
          </li>
          <li>
            <router-link to="/solucoes/operadoras-de-planos/sistema-de-contabilidade-gerencial"
              >Contabilidade Gerencial</router-link
            >
          </li>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/sistema-de-gerenciamento-patrimonial"
              >Gerenciamento Patrimonial</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="modulos">
      <div class="modulos-content">
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#contasPagarReceber"
            >
              <span
                class="container-img operadoras-contas-pagar-receber"
              ></span>
              <span>Contas à Pagar e a Receber</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#relatoriosGerenciais"
            >
              <span
                class="container-img operadoras-relatorios-gerenciais"
              ></span>
              <span>Relatórios Gerenciais</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <div class="modal fade" id="contasPagarReceber" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-contas-pagar-receber"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Contas à Pagar e a Receber</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <p>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="relatoriosGerenciais" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-relatorios-gerenciais"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Relatórios Gerenciais</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <p>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
    methods: {
        Home() {
      window.location.href = '/Home'
    },
  }
};
</script>
