<template>
  <div class="pag-solucoes pag-solucoes-operadoras">

    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão de Operadoras</li>
          <li><span>Cartão Saúde</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content-5">
        <ul>
         <li>
            <router-link
              to="/solucoes/operadoras-de-planos/gestao-operadora/#n"
              >Gestão da Operadora</router-link
            >
          </li>
         <li>
            <router-link to="/solucoes/operadoras-de-planos/faturamento/#n"
              >Faturamento</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/operadoras-de-planos/controle-administrativo/#n"
              >Controle Administrativo</router-link
            >
          </li>
          <li>
            <span>Cartão Saúde</span>
          </li>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/ans-agencia-nacional-de-saude/#n"
              >ANS</router-link
            >
          </li>
        </ul>
      </div>
    </div>

   <!-- ##### MODULOS ##### -->
    <div class="modulos">
      <div class="modulos-content">
        <div class="row">

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#beneficiarios-doadores"
            >
              <span class="container-img operadoras-beneficiarios-doadores"></span>
              <span>Beneficiários / Doadores</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#produtos"
            >
              <span
                class="container-img operadoras-produtos"
              ></span>
              <span>Produtos</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a class="modulo-card" data-toggle="modal" data-target="#vendas">
              <span class="container-img operadoras-vendas"></span>
              <span>Vendas</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a class="modulo-card" data-toggle="modal" data-target="#cobranca">
              <span class="container-img operadoras-cobranca"></span>
              <span>Cobrança</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#autorizacao-de-atendimento"
            >
              <span class="container-img operadoras-autorizacao-de-atendimento"></span>
              <span>Autorização de atendimento</span>
            </a>
          </div>   

           <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#faturamentosaude"
            >
              <span class="container-img operadoras-faturamentosaude"></span>
              <span>Faturamento</span>
            </a>
          </div>   

         
        </div>
      </div>
    </div>


 <!-- ##### MODAL ##### -->

    <div id="beneficiarios-doadores" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-beneficiarios-doadores"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Beneficiários / Doadores</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Gerenciamento da carteira do cartão de doação/benefícios, com inclusões, alterações, 
                      exclusões dentro da mesma tela, de forma intuitiva e dinâmica;</li>
                      <li>Ficha financeira desmembrada empresa/beneficiário/doadores e titular/dependentes;</li>
                      <li>Extrato individualizado de pagamentos anuais, para fins de imposto de renda;</li>
                      <li>Controle de carência, caso exista, conforme produto do beneficiário ou customizado;</li>
                      <li>Cadastro individualizado de descontos/acréscimos aplicados à cobrança do beneficiário/doador.</li>     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="produtos" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-operadoras-produtos"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Produtos</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                      Gestão dos produtos oferecidos pelo cartão de benefícios, com parametrização inicial única;
                      </li>
                      <li>
                      Inclusão das vigências de valores por faixas etárias previamente cadastradas separadas por 
                      titulares, dependentes e agregados;
                      </li>
                      <li>Criação de grupos de procedimentos para definição de cobertura oferecida do produto;</li>
                      <li>Criação de exceções de procedimentos, possibilitando a operadora individualizar a 
                      cobertura, quantidade limite e percentuais/valores de coparticipação;
                      </li>
                      <li>Reajustes por faixas de planos e faixas etárias.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="vendas" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-vendas"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Vendas</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                      Gestão de vendas com tratamento por equipe de venda ou venda individualizada 
                      com definição de parcelas e percentuais.
                      </li>
                      <li>
                      Cadastro de vendedores categorizados, conforme representante, vendedor de planos 
                      individuais/familiares, vendedor de planos coletivos, supervisores, gerentes.
                      </li>
                      <li>
                      Cadastro de fontes de vendas para fornecer dados para gestão dos principais 
                      canais de prospecção de beneficiários/doadores integração dos pagamentos no módulo RPA 
                      </li>   
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="cobranca" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-cobranca"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Cobrança</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                       Permite o cartão de benefícios realizar suas cobranças para mais de um 
                       banco com vários layouts já preestabelecidos;
                      </li>
                      <li>
                       O sistema permite várias formas de envio da cobrança: boletos e 
                       carnês impressos pelo próprio sistema, juntamente ao arquivo de registro, 
                       exportação bancária, conforme layout predefinido pelo banco, boletos pré-impressos 
                       e até mesmo carnês criados pela própria operadora;
                      </li>
                      <li>
                       Baixa nos títulos por pagamento via arquivos eletrônicos bancários e baixas 
                       de manuais com cálculo de juros e multa;
                      </li>
                      <li>
                       Possibilidade da criação automática de notas ficais dos itens pagos;
                      </li>      
                      <li>
                       Definição de mensagens para impressão nos boletos/carnês.Criação de cobranças 
                       avulsas, sem vínculo contratual, com valores definidos pelo usuário;
                      </li> 
                      <li>
                       O sistema dispõe da rotina de acordos e parcelamentos de cobranças em 
                       atraso ou mesmo aquelas que ainda estão em dia, facilitando a negociação entre 
                       o setor de cobrança, beneficiários e empresas contratantes. Nela, podem ser 
                       definidos o valor e o vencimento da primeira parcela e o número de parcelas totais,
                        bem como o intervalo de dias de vencimento entre as demais parcelas;
                      </li> 
                      <li>
                       Integração com os módulos financeiro e contabilidade;
                      </li> 
                      <li>
                       Controle de caixa, com todas as movimentações de entradas e saídas. 
                      </li> 
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="autorizacao-de-atendimento" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-autorizacao-de-atendimento"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Autorização de atendimento</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Emissão de autorizações já integradas com faturamento, com todas as consistências necessárias para validação da autorização: carência, cobertura, quantidade contratual, inadimplência, entre outros;
                      </li>
                      <li>
                        Emissão de guia conforme modelo preestabelecido pelo cartão de benefícios;
                      </li>
                      <li>
                        Possibilidade de seleção do prestador (clínica/hospital) de atendimento, o prestador 
                        executante e entidade de repasse do atendimento;
                      </li>
                      <li>
                        Geração de cobrança via balcão sobre os eventos coparticipativos.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<div id="faturamentosaude" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-faturamentosaude"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Faturamento</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Parametrização de pagamento e tabelas aos prestadores individualizados, permitindo uma flexibilidade de contratos;
                      </li>
                      <li>
                        Gerenciamento das guias e seus lançamentos em tela única, facilitando a transição dos diversos tipos de lançamentos em guias mais complexas;
                      </li>
                      <li>
                        Integração dos pagamentos no módulo RPA.
                      </li>  
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 

    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
    methods: {
        Home() {
      window.location.href = '/Home'
    },
  }
};
</script>
