<template>
  <div class="pag-solucoes pag-solucoes-hospitais">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <MenuSolucoes />

    <div class="modulos">
      <div class="modulos-content">
        <h4 class="center q-gutter-lg">Selecione os módulos abaixo, preencha o formulário e baixe nosso portifólio.</h4>
        <div class="row">
            <div class="col col-1-4" onclick="window.open('http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-assistencial');">
              <label class="label-modulo">
                <span class="modulo-text">ASSISTENCIAL</span>
              </label>
            </div>
            <div class="col col-1-4" onclick="window.open('http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-administrativo');">
              <label class="label-modulo">
                <span class="modulo-text">ADMINISTRATIVO</span>
              </label>
            </div>
            <div class="col col-1-4" onclick="window.open('http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-apoio');">
              <label class="label-modulo">
                <span class="modulo-text">APOIO</span>
              </label>
            </div>
            <div class="col col-1-4" onclick="window.open('http://www-spdata-com-br-1.rds.land/portfolio-spdata-sgh-suprimentos');">
              <label class="label-modulo">
                <span class="modulo-text">SUPRIMENTOS</span>
              </label>
            </div>
          </div>
      </div>
    </div>

    <Cta />

    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
  methods: {
    Home() {
      window.location.href = "/Home";
    },
  },
};
</script>
