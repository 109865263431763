<template>
  <div class="pag-solucoes pag-solucoes-operadoras">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão de Operadoras</li>
          <li><span>Controle Administrativo</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content-5">
        <ul>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/gestao-operadora/#n"
              >Gestão da Operadora</router-link
            >
          </li>
           <li>
            <router-link to="/solucoes/operadoras-de-planos/faturamento/#n"
              >Faturamento</router-link
            >
          </li>
             <li>
           <span>Controle Administrativo </span>
          </li>
         <li>
            <router-link
              to="/solucoes/operadoras-de-planos/cartao-saude/#n"
              >Cartão Saúde</router-link
            >
          </li>
           <li>
            <router-link
              to="/solucoes/operadoras-de-planos/ans-agencia-nacional-de-saude/#n"
              >ANS</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- ##### MODULOS ##### -->
    <div class="modulos">
      <div class="modulos-content">
        <div class="row">

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#sgh-intelligence"
            >
              <span class="container-img operadoras-sgh-intelligence"></span>
              <span>SGH Intelligence - Business Intelligence</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#repasse-rpa"
            >
              <span
                class="container-img operadoras-repasse-rpa"
              ></span>
              <span>GRT - Gestão de Repasses e Terceiros</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a class="modulo-card" data-toggle="modal" data-target="#gestao-financeira">
              <span class="container-img operadoras-gestao-financeira"></span>
              <span>Gestão Financeira</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a class="modulo-card" data-toggle="modal" data-target="#relatorios-gerenciais">
              <span class="container-img operadoras-relatorios-gerenciais"></span>
              <span>Relatórios Gerenciais</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#sistema-de-contabilidade"
            >
              <span class="container-img operadoras-sistema-de-contabilidade"></span>
              <span>Sistema de Contabilidade</span>
            </a>
          </div>


        </div>
      </div>
    </div>


 <!-- ##### MODAL ##### -->

    <div id="sgh-intelligence" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-operadoras-sgh-intelligence"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>SGH Intelligence - Business Intelligence</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Aplicações e funções analíticas pré-construídas focadas no contexto de hospitais,
                      clínicas e planos de saúde;</li>
                      <li>Gerenciamento dos processos de extração, transformação, integração, validação e carga dos dados no <i>Data Warehouse</i> (ETL);</li>
                      <li>Hipercubos OLAP;</li>
                      <li>Dashboards (painéis executivos) compostos de gráficos, tabelas e indicadores;</li>
                      <li>Relatórios customizáveis, analíticos e sintéticos, que integram e relacionam dados de todos os processos;</li>
                      <li>Gestão dos indicadores, com definição de metas e acompanhamento diário da execução dos mesmos;</li>
                      <li>Acesso via navegador web, possibilitando, inclusive, o acesso por dispositivos móveis;</li>
                      <li>Entrega automatizada de informações por e-mail e SMS;</li>
                      <li>A arquitetura tecnológica disponibilizada pelo SGH <i>Intelligence</i> suporta o desenvolvimento rápido de aplicações analíticas totalmente personalizadas e integradas tanto ao SGH quanto a outros sistemas de informação (ERP, CRM, SCM etc.) existentes em sua empresa, independente do fornecedor ou plataforma;</li>
                      <li>Buscando reduzir o custo total de propriedade (TCO – <i>Total Cost Ownership</i>) da solução e entregar o que há de mais avançado no mercado de <i>Business Intelligence</i>, o SGH <i>Intelligence</i> é parcialmente baseado em soluções livres, <i>softwares Open Source</i>, amplamente reconhecidas pelo mercado.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="repasse-rpa" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-operadoras-repasse-rpa"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>GRT - Gestão de Repasses e Terceiros</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        <strong>Aprimoramento do Planejamento Financeiro</strong> <br>
                        Nossa solução oferece a notável vantagem de proporcionar um fluxo de caixa completo, mesmo antes da emissão das notas fiscais pelos prestadores de serviços. Isso se traduz em uma visão precisa e em tempo real das receitas e despesas, permitindo um planejamento financeiro mais eficiente e uma projeção de resultados mais precisa. Essa capacidade contribui significativamente para uma gestão financeira mais eficaz e para a tomada de decisões estratégicas embasadas em dados concretos.
                      </li>
                      <li>
                        <strong>Automação e Eficiência no Gerenciamento de Repasses</strong> <br>
                        Nossa solução proporciona uma gestão altamente eficiente dos repasses financeiros, otimizando o gerenciamento dos provisionamentos a serem pagos por serviços prestados por terceiros na área da saúde. A integração com os módulos de faturamento elimina retrabalhos e erros, fornecendo uma visão completa e sempre atualizada dos lançamentos relacionados aos profissionais de saúde prestadores de serviços. A funcionalidade de mala direta, por sua vez, oferece uma visão completa e transparente dos repasses aos profissionais médicos, aprimorando a gestão e agilizando o processo de pagamento. Isso resulta na satisfação e confiança contínuas dos profissionais de saúde que colaboram com sua instituição.
                      </li>
                      <li>
                        <strong>Conformidade Regulatória e Conciliação de Dados</strong> <br>
                        Entendemos a importância do estrito cumprimento das obrigações regulatórias, e nossa solução vai além do mero envio de informações para a EFD-Reinf. O painel de conciliação específico para a família R-4000, um recurso diferencial da nossa solução "EFD-Reinf," permite que os contadores conciliem minuciosamente todos os lançamentos antes do envio. Isso garante a precisão e conformidade das informações, mitigando potenciais erros e contribuindo para o cumprimento rigoroso dos requisitos regulatórios.
                        <br>
                        Além disso, nossa solução gera automaticamente as pendências de envio dos eventos R-4010 e R-4020 após a conciliação dos dados. Isso assegura que as informações estejam prontas e livres de atrasos e inconsistências no momento do envio à EFD-Reinf.
                      </li>
                      <li>
                        <strong>Preservação da Integridade dos Dados</strong> <br>
                        A integridade dos dados é uma prioridade fundamental em nosso sistema. Portanto, nosso sistema é projetado para evitar alterações em informações de parcelas ou documentos que já tenham sido conciliados. Isso assegura que os dados enviados à EFD-Reinf sejam mantidos em sua forma original, confiáveis e em total conformidade com os requisitos regulatórios.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="gestao-financeira" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-gestao-financeira"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Gestão Financeira</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                      Personalização das entradas de dados conforme parâmetros pré-definidos;
                      </li>
                      <li>Possibilidade de gerenciar várias empresas;</li>
                      <li>Cadastro de clientes, fornecedores, bancos, usuários, plano de contas etc.;</li>
                      <li>Lançamentos de contas a pagar, contas a receber – pacientes, convênios e/ou
                      prestadores de serviço;</li>
                      <li>Pagamento com todas as deduções de impostos – ISS, INSS, IR, PIS, COFINS;</li>
                      <li>Fechamento de contas de pacientes;</li>
                      <li>Controle do parcelamento das contas de pacientes;</li>
                      <li>Conta corrente por convênio;</li>
                      <li>Conta corrente por médico;</li>
                      <li>Movimentação bancária;</li>
                      <li>Conciliação bancária – Permite trabalhar com saldo real ou provisionado;</li>
                      <li>Resumo da movimentação bancária;</li>
                      <li>Emissão e controle de cheques.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="relatorios-gerenciais" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-relatorios-gerenciais"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Relatórios Gerenciais</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                       Contas a receber – por tipo de fornecedor, por fornecedor, por vencimento, de paciente;
                      </li>
                      <li>
                       Cheques emitidos, pendentes ou ambos;
                      </li>
                      <li>
                       Contas a pagar – contas pagas, pendentes ou ambas;
                      </li>
                      <li>
                       Extratos bancários;
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="sistema-de-contabilidade" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-sistema-de-contabilidade"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Contabilidade</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Parâmetros do sistema para definição de critérios de acessos, forma de uso e campos necessários;
                      </li>
                      <li>
                        Atende modelo Multiempresa;
                      </li>
                      <li>
                        Plano de contas em conformidade com o padrão da ANS, e/ou estrutura própria;
                      </li>
                      <li>
                        Uso de Código reduzido;
                      </li>
                      <li>
                        Gerenciamento dos usuários de acordo com seu perfil;
                      </li>
                      <li>
                        Possibilita várias formas de escrituração;
                      </li>
                      <li>
                        Lançamentos em partidas simples ou dobradas;
                      </li>
                      <li>
                        Possibilita definir lançamentos padrões;
                      </li>
                      <li>
                        Fechamentos automáticos mensal, trimestrais ou anuais;
                      </li>
                      <li>
                        Geração de gráficos para visualização de resultados;
                      </li>
                      <li>
                        Relatórios contábeis como: balanço, balancete, razão entre outros.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
    methods: {
        Home() {
      window.location.href = '/Home'
    },
  }
};
</script>
