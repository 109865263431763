<template>
  <div class="pag-quem-somos">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Sobre</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
          <li>Home</li>
          <li><span>Sobre</span></li>
        </ul>
      </div>
    </div>

    <section id="apresentacao">
      <div class="container pad-lg">
        <h3 class="center" data-aos="fade-up">Conheça a SPDATA</h3>
        <p class="left pad-top-md" data-aos="fade-up">
          <span class="text-sm">
            A <b>SPDATA</b> é uma empresa 100% nacional, sendo uma das principais em tecnologia
            para a saúde do país. Há mais de 35 anos desenvolvendo soluções completas para
            Instituições de Saúde de todos os portes, tem como propósito contribuir com o
            desenvolvimento da gestão em saúde.
          </span
          >
        </p>
        <p class="left pad-top-sm" data-aos="fade-up">
          <span class="text-sm">
            Com sede em Contagem, região metropolitana de Minas Gerais,
            a <b>SPDATA</b> foi além das fronteiras mineiras, e hoje está presente em 18 estados.
            Além disso, têm uma estrutura de profissionais especializados localizados dentro e fora do país.
          </span>
        </p>

        <!--
        <div class="btn-container pad-top-lg center">
          <router-link
            to="/solucoes/clinicas-e-consultorios/minha-clinica"
            class="btn btn-lg btn-pri"
            data-aos="fade-up"
            >Nossas soluções</router-link
          >
        </div>
         -->
      </div>
    </section>

    <section id="missao-visao-valores">
      <div class="container pad-lg">
        <div class="row">
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="200">
            <div class="card-item card-missao">
              <span class="ico-mvv ico-missao"></span>
              <h4>Missão</h4>
              <p>
                Elevar o nível de gestão das Instituições de Saúde, visando a sustentabilidade do negócio.
              </p>
            </div>
          </div>
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="400">
            <div class="card-item card-visao">
              <span class="ico-mvv ico-visao"></span>
              <h4>Visão de Futuro</h4>
              <p>
                Ser reconhecida nacionalmente por simplificar a operação e
                gestão das Instituições de Saúde com interoperabilidade.
              </p>
            </div>
          </div>
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="600">
            <div class="card-item card-valores">
              <span class="ico-mvv ico-valores"></span>
              <h4>Valores</h4>
              <li>Atitudes e princípios éticos.</li>
              <li>Desenvolvimento pessoal e profissional.</li>
              <li>Contribuir com as pessoas, isso é respeito!</li>
              <li>Entregamos resultados.</li>
              <li>Compreender para servir.</li>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <TimeLine /> -->

    <section id="diretoria">
      <div class="container pad-lg">
        <h4 class="center" data-aos="fade-up">Conselho de Administração</h4>
        <div class="row pad-top-md">
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="200">
            <div class="center card-item">
              <h4 class="center">Alberto Jaekel</h4>
              <p class="center">Conselheiro e Sócio Fundador</p>
            </div>
          </div>
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="400">
            <div class="center card-item">
              <h4 class="center">Gervásio Lonczynski</h4>
              <p class="center">Conselheiro e Sócio Fundador</p>
            </div>
          </div>
          <div class="col col-1-3" data-aos="fade-up" data-aos-duration="600">
            <div class="center card-item">
              <h4 class="center">Mário Lonczynski</h4>
              <p class="center">Conselheiro e Sócio Fundador</p>
            </div>
          </div>
        </div>

        <h4 class="center pad-top-lg" data-aos="fade-up">Diretoria</h4>
        <div class="row pad-top-md justify-center">
          <div class="col" data-aos="fade-up" data-aos-duration="200">
            <div class="center card-item">
              <h4 class="center">Mário Lonczynski</h4>
              <p class="center">CEO</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="conformidade">
      <div class="container pad-lg">
        <h3 class="center" data-aos="fade-up">Mensagem da SPDATA</h3>
        <p class="left pad-top-md" data-aos="fade-up">
          <span class="text-sm">
            A SPDATA consolidou-se, no cenário nacional, como uma das maiores
            empresas de tecnologia para a saúde. Para alcançar e manter essa
            condição, a SPDATA, persegue e investe na excelência técnica e
            gerencial de seus colaboradores e na modernização de seus produtos e
            serviços.
          </span>
        </p>
        <p class="left pad-top-sm" data-aos="fade-up">
          <span class="text-sm">
            Além disso, buscamos estabelecer e explicitar valores e princípios
            éticos que reflitam nossa cultura e definam normas e diretrizes para
            as condutas a serem adotadas nas relações interpessoais,
            profissionais e no trato com o patrimônio da empresa.</span
          >
        </p>
        <p class="left pad-top-sm" data-aos="fade-up">
          <span class="text-sm">
            É de extrema importância que todos tenham conhecimento das normas
            contidas no código de conformidade que funcionará como um guia na
            relação com clientes, colaboradores e parceiros.</span
          >
        </p>

        <div class="left pad-top-md" data-aos="fade-up">
          <h5>Mário Lonkzynski</h5>
          <h5>CEO</h5>
        </div>
      </div>
    </section>

    <section id="sobre-politica-privacidade">
      <div class="container pad-lg">
        <div class="btn-container center">
          <a
            href="http://app.protegon.com.br/#/external_request/d457b7fa"
            target="_blank"
            class="btn btn-lg btn-destaque"
            data-aos="fade-up"
            >Política de Privacidade</a
          >
        </div>
      </div>
    </section>

    <section id="canal-denuncias">
      <div class="container pad-lg">
        <h3 class="center" data-aos="fade-up">Canal de Denúncia</h3>
        <p class="left pad-top-md" data-aos="fade-up">
          <span class="text-sm">
            É um canal exclusivo da SPDATA para comunicação segura e, se
            desejada, anônima, de condutas consideradas antiéticas ou que violem
            os princípios éticos e padrões de conduta e/ou a legislação vigente.
          </span>
        </p>
        <p class="left pad-top-sm" data-aos="fade-up">
          <span class="text-sm">
            As informações aqui registradas serão recebidas por uma empresa
            independente e especializada, a PROTEGON, assegurando sigilo
            absoluto e o tratamento adequado de cada situação pela alta
            administração da SPDATA, sem conflitos de interesses.
          </span>
        </p>
        <div class="btn-container center">
          <a
            href="http://app.protegon.com.br/#/external_incident_complaint/3bd45c78"
            target="_blank"
            class="btn btn-lg btn-sec"
            data-aos="fade-up"
            >Acesse Aqui</a
          >
        </div>
      </div>
    </section>

    <!--
    <section id="responsabilidade-social">
      <div class="container pad-top-lg">
        <h3 class="center" data-aos="fade-up">Responsabilidade Social</h3>
        <p class="left pad-top-md" data-aos="fade-up">
          <span class="text-sm">
            Investimos constantemente nas relações com as comunidades que
            estamos inseridos, através do desenvolvimento de programas sociais,
            estreitando os laços de parceria.
          </span>
        </p>
        <h5 class="pad-top-md" data-aos="fade-up">Menor Aprendiz</h5>
        <p class="left pad-top-sm" data-aos="fade-up">
          <span class="text-sm">
            A empresa possui parceria com associações para contratação de
            menores aprendizes, que têm a oportunidade de iniciar uma carreira
            profissional sem abandonar os estudos. Monitorado pelo governo, o
            programa também oferece treinamentos semanais realizados nas
            próprias associações.</span
          >
        </p>
        <h5 class="pad-top-md" data-aos="fade-up">Segurança e Saúde</h5>
        <p class="left pad-top-sm" data-aos="fade-up">
          <span class="text-sm">
            Trabalhar com segurança e contribuir para o desenvolvimento das
            pessoas e da sociedade são valores da SPDATA. A empresa investe
            continuamente em práticas de promoção da saúde e prevenção de
            acidentes e de desenvolvimento de sua equipe.</span
          >
                  <ul>
          <li>Programas de Incentivo com foco no uso de EPIs e cumprimento dos procedimentos de segurança.</li>
          <li>Treinamentos Técnicos e Comportamentais.</li>
          <li>Campanhas de Comunicação Interna.</li>
          <li>Programa de Saúde e Bem-estar – parceria com a Unimed.</li>
          <li>Universidade SPDATA – incentivo à educação continuada.</li>
          <li>Programas de Desenvolvimento das Lideranças, entre outros.</li>
        </ul>
        </p>


      </div>
    </section>

        <section id="sustentabilidade">
      <div class="container pad-lg">
        <h3 class="center" data-aos="fade-up">Sustentabilidade</h3>
        <p class="left pad-top-md" data-aos="fade-up">
          <span class="text-sm">
            A SPDATA assume o compromisso com a melhoria contínua dos seus processos, reduzindo a utilização de recursos naturais e preservando o meio ambiente. Para garantir a aplicação de sua Política de Meio Ambiente, a empresa desenvolve diversos projetos na área.</span
          >
        </p>
        <p class="left" data-aos="fade-up">
          <span class="text-sm">
            Entre as ações desenvolvidas, pode-se destacar:
          </span>
                  <ul>
          <li>Descarte correto de lixo.</li>
          <li>Destinação correta para lixo eletrônico e produtos nocivos ao meio ambiente.</li>
        </ul>
        </p>


      </div>
    </section>
-->

    <!-- <section id="quem-somos-foto"></section> -->

    <Cta />

  </div>
</template>

<script>
import Cta from "@/components/Cta.vue";
export default {
  name: "quem-somos",
  components: {
    Cta
  },

  methods: {
    Home() {
      window.location.href = "/";
    },
  },
};
</script>
