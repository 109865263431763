var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pag-quem-somos"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('Cta')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pag-title"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title center"},[_vm._v("Sobre")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"breadcrumb"}},[_c('div',{staticClass:"container"},[_c('ul',[_c('li',[_vm._v("Home")]),_c('li',[_c('span',[_vm._v("Sobre")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"apresentacao"}},[_c('div',{staticClass:"container pad-lg"},[_c('h3',{staticClass:"center",attrs:{"data-aos":"fade-up"}},[_vm._v("Conheça a SPDATA")]),_c('p',{staticClass:"left pad-top-md",attrs:{"data-aos":"fade-up"}},[_c('span',{staticClass:"text-sm"},[_vm._v(" A "),_c('b',[_vm._v("SPDATA")]),_vm._v(" é uma empresa 100% nacional, sendo uma das principais em tecnologia para a saúde do país. Há mais de 35 anos desenvolvendo soluções completas para Instituições de Saúde de todos os portes, tem como propósito contribuir com o desenvolvimento da gestão em saúde. ")])]),_c('p',{staticClass:"left pad-top-sm",attrs:{"data-aos":"fade-up"}},[_c('span',{staticClass:"text-sm"},[_vm._v(" Com sede em Contagem, região metropolitana de Minas Gerais, a "),_c('b',[_vm._v("SPDATA")]),_vm._v(" foi além das fronteiras mineiras, e hoje está presente em 18 estados. Além disso, têm uma estrutura de profissionais especializados localizados dentro e fora do país. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"missao-visao-valores"}},[_c('div',{staticClass:"container pad-lg"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-1-3",attrs:{"data-aos":"fade-up","data-aos-duration":"200"}},[_c('div',{staticClass:"card-item card-missao"},[_c('span',{staticClass:"ico-mvv ico-missao"}),_c('h4',[_vm._v("Missão")]),_c('p',[_vm._v(" Elevar o nível de gestão das Instituições de Saúde, visando a sustentabilidade do negócio. ")])])]),_c('div',{staticClass:"col col-1-3",attrs:{"data-aos":"fade-up","data-aos-duration":"400"}},[_c('div',{staticClass:"card-item card-visao"},[_c('span',{staticClass:"ico-mvv ico-visao"}),_c('h4',[_vm._v("Visão de Futuro")]),_c('p',[_vm._v(" Ser reconhecida nacionalmente por simplificar a operação e gestão das Instituições de Saúde com interoperabilidade. ")])])]),_c('div',{staticClass:"col col-1-3",attrs:{"data-aos":"fade-up","data-aos-duration":"600"}},[_c('div',{staticClass:"card-item card-valores"},[_c('span',{staticClass:"ico-mvv ico-valores"}),_c('h4',[_vm._v("Valores")]),_c('li',[_vm._v("Atitudes e princípios éticos.")]),_c('li',[_vm._v("Desenvolvimento pessoal e profissional.")]),_c('li',[_vm._v("Contribuir com as pessoas, isso é respeito!")]),_c('li',[_vm._v("Entregamos resultados.")]),_c('li',[_vm._v("Compreender para servir.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"diretoria"}},[_c('div',{staticClass:"container pad-lg"},[_c('h4',{staticClass:"center",attrs:{"data-aos":"fade-up"}},[_vm._v("Conselho de Administração")]),_c('div',{staticClass:"row pad-top-md"},[_c('div',{staticClass:"col col-1-3",attrs:{"data-aos":"fade-up","data-aos-duration":"200"}},[_c('div',{staticClass:"center card-item"},[_c('h4',{staticClass:"center"},[_vm._v("Alberto Jaekel")]),_c('p',{staticClass:"center"},[_vm._v("Conselheiro e Sócio Fundador")])])]),_c('div',{staticClass:"col col-1-3",attrs:{"data-aos":"fade-up","data-aos-duration":"400"}},[_c('div',{staticClass:"center card-item"},[_c('h4',{staticClass:"center"},[_vm._v("Gervásio Lonczynski")]),_c('p',{staticClass:"center"},[_vm._v("Conselheiro e Sócio Fundador")])])]),_c('div',{staticClass:"col col-1-3",attrs:{"data-aos":"fade-up","data-aos-duration":"600"}},[_c('div',{staticClass:"center card-item"},[_c('h4',{staticClass:"center"},[_vm._v("Mário Lonczynski")]),_c('p',{staticClass:"center"},[_vm._v("Conselheiro e Sócio Fundador")])])])]),_c('h4',{staticClass:"center pad-top-lg",attrs:{"data-aos":"fade-up"}},[_vm._v("Diretoria")]),_c('div',{staticClass:"row pad-top-md justify-center"},[_c('div',{staticClass:"col",attrs:{"data-aos":"fade-up","data-aos-duration":"200"}},[_c('div',{staticClass:"center card-item"},[_c('h4',{staticClass:"center"},[_vm._v("Mário Lonczynski")]),_c('p',{staticClass:"center"},[_vm._v("CEO")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"conformidade"}},[_c('div',{staticClass:"container pad-lg"},[_c('h3',{staticClass:"center",attrs:{"data-aos":"fade-up"}},[_vm._v("Mensagem da SPDATA")]),_c('p',{staticClass:"left pad-top-md",attrs:{"data-aos":"fade-up"}},[_c('span',{staticClass:"text-sm"},[_vm._v(" A SPDATA consolidou-se, no cenário nacional, como uma das maiores empresas de tecnologia para a saúde. Para alcançar e manter essa condição, a SPDATA, persegue e investe na excelência técnica e gerencial de seus colaboradores e na modernização de seus produtos e serviços. ")])]),_c('p',{staticClass:"left pad-top-sm",attrs:{"data-aos":"fade-up"}},[_c('span',{staticClass:"text-sm"},[_vm._v(" Além disso, buscamos estabelecer e explicitar valores e princípios éticos que reflitam nossa cultura e definam normas e diretrizes para as condutas a serem adotadas nas relações interpessoais, profissionais e no trato com o patrimônio da empresa.")])]),_c('p',{staticClass:"left pad-top-sm",attrs:{"data-aos":"fade-up"}},[_c('span',{staticClass:"text-sm"},[_vm._v(" É de extrema importância que todos tenham conhecimento das normas contidas no código de conformidade que funcionará como um guia na relação com clientes, colaboradores e parceiros.")])]),_c('div',{staticClass:"left pad-top-md",attrs:{"data-aos":"fade-up"}},[_c('h5',[_vm._v("Mário Lonkzynski")]),_c('h5',[_vm._v("CEO")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"sobre-politica-privacidade"}},[_c('div',{staticClass:"container pad-lg"},[_c('div',{staticClass:"btn-container center"},[_c('a',{staticClass:"btn btn-lg btn-destaque",attrs:{"href":"http://app.protegon.com.br/#/external_request/d457b7fa","target":"_blank","data-aos":"fade-up"}},[_vm._v("Política de Privacidade")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"canal-denuncias"}},[_c('div',{staticClass:"container pad-lg"},[_c('h3',{staticClass:"center",attrs:{"data-aos":"fade-up"}},[_vm._v("Canal de Denúncia")]),_c('p',{staticClass:"left pad-top-md",attrs:{"data-aos":"fade-up"}},[_c('span',{staticClass:"text-sm"},[_vm._v(" É um canal exclusivo da SPDATA para comunicação segura e, se desejada, anônima, de condutas consideradas antiéticas ou que violem os princípios éticos e padrões de conduta e/ou a legislação vigente. ")])]),_c('p',{staticClass:"left pad-top-sm",attrs:{"data-aos":"fade-up"}},[_c('span',{staticClass:"text-sm"},[_vm._v(" As informações aqui registradas serão recebidas por uma empresa independente e especializada, a PROTEGON, assegurando sigilo absoluto e o tratamento adequado de cada situação pela alta administração da SPDATA, sem conflitos de interesses. ")])]),_c('div',{staticClass:"btn-container center"},[_c('a',{staticClass:"btn btn-lg btn-sec",attrs:{"href":"http://app.protegon.com.br/#/external_incident_complaint/3bd45c78","target":"_blank","data-aos":"fade-up"}},[_vm._v("Acesse Aqui")])])])])
}]

export { render, staticRenderFns }