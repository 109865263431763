<template>
  <div class="pag-solucoes pag-solucoes-operadoras">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
          <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão de Operadoras</li>
          <li><span>ANS - Agência Nacional de Saúde</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content-5">
        <ul>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/gestao-operadora/#n"
              >Gestão da Operadora</router-link
            >
          </li>
           <li>
            <router-link to="/solucoes/operadoras-de-planos/faturamento/#n"
              >Faturamento</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/operadoras-de-planos/controle-administrativo/#n"
              >Controle Administrativo</router-link
            >
          </li>
         <li>
            <router-link
              to="/solucoes/operadoras-de-planos/cartao-saude/#n"
              >Cartão Saúde</router-link
            >
          </li>
          <li>
            <span>ANS</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- ##### MODULOS ##### -->
    <div class="modulos">
      <div class="modulos-content">
        <div class="row">

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#sib"
            >
              <span class="container-img operadoras-sib"></span>
              <span>SIB</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#sip"
            >
              <span
                class="container-img operadoras-sip"
              ></span>
              <span>SIP</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a class="modulo-card" data-toggle="modal" data-target="#radar-tiss">
              <span class="container-img operadoras-radar-tiss"></span>
              <span>Radar TISS</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a class="modulo-card" data-toggle="modal" data-target="#tps">
              <span class="container-img operadoras-tps"></span>
              <span>TPS</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#monitoramento-tiss"
            >
              <span class="container-img operadoras-monitoramento-tiss"></span>
              <span>Monitoramento TISS</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#rpc"
            >
              <span class="container-img operadoras-rpc"></span>
              <span>RPC</span>
            </a>
          </div>
        </div>

        <div class="row">

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#dados-indicadores"
            >
              <span
                class="container-img operadoras-dados-indicadores"
              ></span>
              <span>Dados e Indicadores</span>
            </a>
          </div>
         
        </div>
      </div>
    </div>


 <!-- ##### MODAL ##### -->

    <div id="sib" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-operadoras-sib"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>SIB</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                      Sistema informatizado que contém os dados cadastrais dos beneficiários de planos privados de saúde no Brasil. É composto por três grupos de dados os quais devem ser atualizados pelas operadoras sempre que houver alguma alteração.
                      </li>        
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="sip" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-operadoras-sip"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>SIP</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                      O Sistema de Informações de Produtos - SIP é o instrumento regulamentado pela Agência 
                      Nacional de Saúde Suplementar - ANS para envio de informações e acompanhamento da 
                      assistência prestada aos beneficiários dos planos privados de assistência à saúde.
                      </li>            
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="radar-tiss" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-radar-tiss"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Radar TISS</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                      O Radar TISS é uma pesquisa desenvolvida para acompanhar a implantação do padrão TISS pelas 
                      operadoras de planos privados de assistência à saúde e pelos prestadores de serviços de saúde. 
                      Além disso, a pesquisa busca orientar novos estudos e planos de ação da ANS e do Comitê de 
                      Padronização de Informações em Saúde Suplementar (COPISS).                  
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="tps" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-tps"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>TPS</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                       Conforme previsto nos artigos 4º e 5º da RN 89/2005, a Taxa de Saúde Suplementar por 
                       Plano de Assistência à Saúde - TPS é uma espécie de Taxa de Saúde Suplementar - TSS. 
                       Tem como determinantes a quantidade de beneficiários, a cobertura e a área de abrangência 
                       geográfica dos planos de assistência à saúde. A TPS deverá ser recolhida até o último dia 
                       útil do primeiro decêndio dos meses de março, junho, setembro e dezembro de cada ano. 
                      </li>       
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="monitoramento-tiss" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-monitoramento-tiss"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Monitoramento TISS</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        O Monitoramento TISS é uma das etapas de acompanhamento do IDSS, índice que agrega 
                        diversos dados e informações de diversas áreas da operadora, da equipe médica, de 
                        gestão e dos beneficiários.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="rpc" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-rpc"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>RPC</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        As operadoras devem informar os reajustes por variação de custo aplicados sobre os contratos coletivos.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="dados-indicadores" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-dados-indicadores"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Dados e Indicadores</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Fornecimentos dos dados referentes à transparência das informações em saúde suplementar.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
    methods: {
        Home() {
      window.location.href = '/Home'
    },
  }
};
</script>