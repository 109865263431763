<template>
  <div class="pag-solucoes pag-solucoes-operadoras">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão de Operadoras</li>
          <li><span>Controle de Planos</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div class="abas">
      <div class="abas-content">
        <ul>
          <li>
            <span>Controle de Planos</span>
          </li>
          <li>
            <router-link to="/solucoes/operadoras-de-planos/controle-financeiro"
              >Controle Financeiro</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/operadoras-de-planos/sistema-de-contabilidade-gerencial"
              >Contabilidade Gerencial</router-link
            >
          </li>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/ans-agencia-nacional-de-saude"
              >ANS - Agência Nacional de Saúde</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="modulos">
      <div class="modulos-content">
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#comercialVendas"
            >
              <span class="container-img operadoras-comercial-vendas"></span>
              <span>Comercial e Vendas</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#cadastroBeneficiarios"
            >
              <span
                class="container-img operadoras-cadastro-beneficiarios"
              ></span>
              <span>Cadastro de Beneficiários</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#faturamento"
            >
              <span class="container-img operadoras-faturamento"></span>
              <span>Faturamento</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#relatoriosGerenciais"
            >
              <span
                class="container-img operadoras-relatorios-gerenciais"
              ></span>
              <span>Relatórios Gerenciais</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <div class="modal fade" id="comercialVendas" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-comercial-vendas"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Comercial e Vendas</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Controle de percentuais de incidência por clínica,
                        classificação (mat.med), técnica cirúrgica e, diagnose
                        terapia (Ato);
                      </li>
                      <li>
                        Controle de cobrança automática de diária de
                        acompanhante de criança/idoso/paciente especiais;
                      </li>
                      <li>
                        Controle de cobrança automática de taxas de
                        registro/administrativas;
                      </li>
                      <li>
                        Controle de cobrança automática de taxas de recuperação
                        pós anestésica, enfermagem e mat.med;
                      </li>
                      <li>
                        Controle de cobrança automática de Taxa de Sala de
                        acordo com o porte anestésico do procedimento;
                      </li>
                      <li>
                        Controle de cobrança de percentuais de acréscimo para
                        horários especiais (após horário);
                      </li>
                      <li>
                        Controle de cobrança de pacotes (Composição do pacote);
                      </li>
                      <li>
                        Controle de débitos particulares baseado nos valores
                        pagos no controle de glosas e/ou no módulo Financeiro;
                      </li>
                      <li>
                        Controle dos valores de (Preço Máximo ao Consumidor) ou
                        (Preço da Fábrica do Balcão) do Brasíndice por
                        classificação e/ou por item (Item de uso restrito
                        hospitalar, etc.);
                      </li>
                      <li>
                        Controle de faturamento por (Atividade Hospitalar);
                      </li>
                      <li>
                        Controle de geração automática de guias (Padrão TISS)
                        nas Recepções e Faturamento;
                      </li>
                      <li>
                        Controle de Faturamento no (Padrão TISS) guias
                        impressas, XML e webservices (Em desenvolvimento na
                        versão V.17.01);
                      </li>
                      <li>
                        Webservices (Padrão TISS) (Em desenvolvimento na versão
                        V.17.01);
                      </li>
                      <li>Controle de Entrega de Contas.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="cadastroBeneficiarios" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-cadastro-beneficiarios"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Cadastro de Beneficiários</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <p>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="faturamento" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-faturamento"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Faturamento</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <p>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="relatoriosGerenciais" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                                    <span
                    class="modal-container-img modal-operadoras-relatorios-gerenciais"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Relatórios Gerenciais</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <p>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
    methods: {
        Home() {
      window.location.href = '/Home'
    },
  }
};
</script>